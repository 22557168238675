import { Theme } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { useBoolean } from "usehooks-ts";

import { DisabledArea, ImageGrid, Link } from "../";
import { ConfirmDialog } from "../ConfirmDialog/ConfirmDialog";
import { ActiveIndicator } from "./components/ActiveIndicator/ActiveIndicator";
import { ForgeButton } from "./components/ForgeButton/ForgeButton";
import { ImportIndicator } from "./components/ImportIndicator/ImportIndicator";
import { SDXLUpgradeIndicator } from "./components/SDXLUpgradeIndicator/SDXLUpgradeIndicator";
import { StatusIndicator } from "./components/StatusIndicator/StatusIndicator";
import { StyleActionsMenu } from "./components/StyleActionsMenu/StyleActionsMenu";
import { StyleTileFooter } from "./components/StyleTileFooter/StyleTileFooter";
import { StyleActions, StyleStatus } from "./types";

export interface StyleTileProps extends StyleActions {
  styleId: string;
  styleSlug: string;
  styleName: string;
  styleThumbnails: string[];
  styleFavorited: boolean;
  workspaceName: string;
  workspaceDisplayName: string;
  styleStatus?: StyleStatus;
  styleImported?: boolean;
  workspaceLogoSrc?: string;
  styleBaseModelUrl?: string;
  userCanTrainStyles?: boolean;
  styleActive?: boolean;
  styleUpgradable?: boolean;
  onClickStyle?: (event: React.MouseEvent) => void;
  disabled?: boolean;
}

type ContainerProps = {
  disabled: boolean;
};

const Container = styled("div", {
  shouldForwardProp: (prop) => prop !== "disabled",
})<ContainerProps>(
  ({ theme, disabled }: { theme: Theme; disabled: boolean }) => ({
    display: "flex",
    flexDirection: "column",
    transform: "scale(1)",
    transition: theme.transitions.create(["transform"]),
    opacity: disabled ? 0.3 : 1,
    "&:focus-within,&:hover": {
      transform: "scale(1.05)",
    },
  }),
);

const ImageOuterContainer = styled("div")(({ theme }: { theme: Theme }) => ({
  display: "inline-block",
  position: "relative",
  width: "100%",
  flex: 1,
  padding: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  border: `1px solid ${theme.palette.secondary["600"]}`,
  transition: theme.transitions.create(["border"]),

  "&:hover": {
    border: `1px solid ${theme.palette.secondary["300"]}`,

    "& .Layer-ForgeButton": {
      opacity: 1,
    },
  },
}));

const ImageInnerContainer = styled(Link)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  overflow: "hidden",
}));

export const StyleTile = (props: StyleTileProps) => {
  const {
    styleId,
    styleSlug,
    styleName,
    styleThumbnails,
    styleFavorited,
    styleActive,
    styleUpgradable,
    workspaceName,
    workspaceDisplayName,
    workspaceLogoSrc,
    userCanTrainStyles,
    onEdit,
    onForge,
    onUnlink,
    onImport,
    onView,
    onDelete,
    onArchive,
    onUnarchive,
    onDuplicate,
    onCancelTraining,
    onClickStyle,
    disabled,
    styleStatus = "COMPLETE",
    styleImported = false,
  } = props;

  const [anchor, setAnchor] = useState(null);
  const isDeleteConfirmDialogOpen = useBoolean();

  const handleImageClick = (event: React.MouseEvent) => {
    if (onClickStyle) {
      event.preventDefault();
      onClickStyle(event);
    }
  };

  const handleContextMenuOpen = (event: React.MouseEvent) => {
    const isRightClick = event.nativeEvent.button === 2;

    if (isRightClick) {
      event.preventDefault();
      setAnchor({ x: event.clientX, y: event.clientY });
    }
  };

  const handleActionsMenuOpen = (
    event: React.MouseEvent | React.KeyboardEvent,
  ) => {
    const node = event.target as HTMLElement;
    const { left, top, width, height } = node.getBoundingClientRect();
    setAnchor({ x: left + width, y: top + height });
  };

  const handleActionsMenuClose = () => {
    setAnchor(null);
  };

  const handleConfirmDelete = (event) => {
    onDelete(event);
    handleActionsMenuClose();
  };

  const renderTopLeftIndicator = () => {
    if (styleUpgradable) {
      return (
        <SDXLUpgradeIndicator
          upgradeUrl={`/${workspaceName}/styles/${styleId}/info`}
          userCanTrainStyles={userCanTrainStyles}
        />
      );
    }

    return <StatusIndicator styleStatus={styleStatus} />;
  };

  const renderTopRightIndicator = () => {
    if (styleImported) {
      return <ImportIndicator />;
    }

    if (styleActive) {
      return <ActiveIndicator />;
    }
  };

  return (
    <DisabledArea
      disabled={disabled}
      title="This style is not supported for image editing."
      placement="right"
      arrow
    >
      <Container disabled={disabled}>
        <ImageOuterContainer onContextMenu={handleContextMenuOpen}>
          {styleStatus === "COMPLETE" && onForge && (
            <ForgeButton
              styleName={styleName}
              open={!!onForge}
              onClick={onForge}
            />
          )}
          <ImageInnerContainer
            onClick={handleImageClick}
            href={`/${workspaceName}/styles/${styleSlug}`}
          >
            {renderTopLeftIndicator()}
            {renderTopRightIndicator()}
            <ImageGrid imageSrcs={styleThumbnails} rows={2} columns={2} />
          </ImageInnerContainer>
        </ImageOuterContainer>
        <StyleTileFooter
          styleId={styleId}
          styleName={styleName}
          styleFavorited={styleFavorited}
          workspaceName={workspaceDisplayName}
          workspaceLogoSrc={workspaceLogoSrc}
          onActionMenuOpen={handleActionsMenuOpen}
        />
        <StyleActionsMenu
          open={!!anchor}
          anchor={anchor}
          styleStatus={styleStatus}
          onEdit={onEdit}
          onView={onView}
          onForge={onForge}
          onUnlink={onUnlink}
          onImport={onImport}
          onDuplicate={onDuplicate}
          onCancelTraining={onCancelTraining}
          onUnarchive={onUnarchive}
          onArchive={onArchive}
          onDelete={onDelete && isDeleteConfirmDialogOpen.setTrue}
          onClose={handleActionsMenuClose}
        />
        <ConfirmDialog
          title="Are you sure you want to delete this style?"
          description="Once a style has been deleted, it cannot be be retrieved"
          confirmButtonProps={{
            color: "error",
          }}
          open={isDeleteConfirmDialogOpen.value}
          onConfirm={handleConfirmDelete}
          onClose={isDeleteConfirmDialogOpen.setFalse}
        />
      </Container>
    </DisabledArea>
  );
};
